@font-face {
  font-family: 'NotoSans-Regular';
  src: url('NotoSans-Regular.ttf');
}

.header {
  text-align: center;
  text-decoration: none;
}

.title {
  text-align: center;
  font-size:40px;
  color:#082172;
  font-style:normal;
  font-family:NotoSans-Regular;
}

.nav-item {
  padding-left:30px;
  padding-right:30px;
}

.main-content {
  font-size: 18px;
  font-family: NotoSans-Regular;
  font-style:normal;
}

.main-link {
  color: #082172;
  font-style:normal;
  font-family:NotoSans-Regular;
}

.section-title {
  text-align: left;
  text-decoration: none;
  color:#082172;
  font-style:normal;
  font-family:NotoSans-Regular;
}

.paper-title {
  text-align: left;
  color:#000000;
  font-style:normal;
  font-family:NotoSans-Regular;
}

.paper-link {
  color: #000000;
  font-style:normal;
  font-family:NotoSans-Regular;
}

.paper-author {
  font-size: 15px;
  font-family: NotoSans-Regular;
  font-style: italic;
}

.paper-abstract {
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
  font-style: normal;
}